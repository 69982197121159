@import "bootstrap";
@import "animations";

@import 'node_modules/swiper/swiper-bundle.min.css';
/* General App Classes
======================================== */
.brand-gradient {
  background: linear-gradient(151.38deg, rgba(33, 150, 243, 0.77) 0%, rgba(125, 29, 142, 0.77) 100%);
  color: $white;
  height: 100%;
}
.id90-logo {
  max-height: 30px;
  max-width: 280px;
}
.min-logo-width {
  min-width: 69px;
}

// Prevent Body Scrolling when modal is open
body.full-screen-modal-open {
  overflow: hidden;
}

/* Text Styles
==================================== */
.profile-title {
  border-left: 3px solid $primary;
  padding-left: 15px;
  margin-bottom: 20px;
}
.primary-content {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
}
.secondary-content {
  font-size: 16px;
  font-weight: 400;
}
.color-light {
  color: $temp-light !important;
}
.text-small {
  font-size: 14px;
}
.tertiary-content {
  font-size: 14px;
  color: $temp-light;
  font-weight: 300;
}
.sub-title {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: $temp-light;
}
.main-header {
  font-size: 35pt;
  font-weight: 400;
  color: $dark;
}
p.title {
  font-weight: 700;
  font-size: 13pt;
}
.clickable {
  cursor: pointer;
}
.primary-color {
  color: $primary;
}
.secondary-color {
  color: $secondary;
}
.gray-medium-color {
  color: $gray-medium;
}
.no-text-decoration {
  text-decoration: none !important;
}
.underline-dotted {
  text-decoration: underline;
  text-decoration-style: dotted;
}
.cursor-pointer {
  cursor: pointer;
}
.text-spacing-1 {
  letter-spacing: normal !important;

  * {
    letter-spacing: normal !important;
  }
}
.text-height-1 {
  line-height: normal !important;

  * {
    line-height: normal !important;
  }
}

/* Shopping Filters
==================================== */
.search-buttons {
  justify-content: space-between;
  button {
    font-size: 12px;
    padding: 0 0.75rem;
    border-radius: 30px;
  }
}
@include media-breakpoint-down(lg) {
  .search.search-component {
    padding: 5px 15px;
  }
}
.search.search-component {
  background-color: $gray-light;
  @include media-breakpoint-up(lg) {
    &:after {
      width: 0px;
    }
  }
}
.search-component {
  align-items: center;
  display: flex;
  position: relative;
  padding: 15px 20px;
  @include media-breakpoint-down(lg) {
    padding: 15px 25px;
    border-bottom: 1px solid $gray-light;
  }
  @include media-breakpoint-up(lg) {
    @include separator($gray);

    &:last-child:after {
      width: 0px;
    }
  }
}

/* Utility Classses
==================================== */
.gray-blue-background {
  background: $gray-blue;
}
.default-background {
  background-color: lighten($gray-light, 2.3%);
}
.full-screen-height {
  height: 100vh;
}
.sticky-content {
  position: sticky;
  top: 85px;
}
.border {
  border-radius: 6px;
}

/* Form Styles
==================================== */
.form-control:focus {
  box-shadow: none;
}
label {
  font-weight: bold;
}
.input-icon {
  border-radius: 0;
  color: $gray-medium;
  font-size: 22px;
  height: 100%;
  padding: 24px 16px;
  position: absolute;
  right: 0px;
  bottom: 10px;
}
.input-group > .form-control:not(:last-child), .input-group > .form-select:not(:last-child) {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.form-select {
  height: auto;
}
.form-group {
  position: relative;
  margin-bottom: 1.9rem;
  margin-top:15px;
}

// CSS Avoided in IE
@supports not (-ms-high-contrast: none) {

  .form-group {
    margin-bottom: 1.2rem;
    margin-top: 0px;
  }
  .form-control {
    margin-top: 0px;
    border: 1px solid $gray;
    padding-top: 1.15rem;
    border-radius: 8px;
    min-height: 50px;
  }
  .form-control:focus {
    background-color: $gray-light;
    border: 1px solid $gray;
    transition: ease-in-out 0.25s;
  }
  .with-background {
    background-color: $gray-light;
  }
  .form-floating {
    .form-control {
      ~ label {
        font-weight: 300;
        font-size: 13px;
      }
      &:focus,
      &:active,
      &:visited,
      &:valid {
        ~ label::after {
          background-color: unset;
        }
      }
      &:not(:focus) {
        ~ label {
          color: $gray-medium;
        }
        &:placeholder-shown ~ label {
          opacity: 0.5;
        }
      }
      &:not(:placeholder-shown) {
        ~ label::after {
          background-color: unset;
        }
      }
    }
    input {
      background-color: $gray-light;
    }
  }
}

input[readonly] {
  background-color: $input-disabled-bg;
}

  /* Card Styles
==================================== */
.card {
  -webkit-box-shadow: 0 0.625rem 1.25rem 0 rgba($gray-medium,.1);
  box-shadow: 0 0.625rem 1.25rem 0 rgba($gray-medium,.1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  img {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}
.clickable-card {
  cursor: pointer;
  transition: transform .5s;
}
.clickable-card:hover {
  transform: translateY(-10px);
}
.shadow-top {
  box-shadow: 0 -0.625rem 1.25rem 0 rgba($gray-medium,.1);
}
.card-header {
  background-color: $gray-light;
}

/* Dropdown Styles
==================================== */
.dropdown-item.active, .dropdown-item:active {
  background: $gray-light;
  color: $dark;
}
.ng-select .ng-select-container {
  border: 0px !important;
  padding: 0px !important;
  color: rgb(70,70,90) !important;
  cursor: pointer !important;
  -webkit-appearance: none !important;
  .ng-arrow-wrapper {
    visibility: hidden;
  }
  .ng-value-container {
    flex-wrap: nowrap !important;
  }
}

/* Button Styles
==================================== */
.btn-primary {
  box-shadow: 0 0.25rem 0.50rem 0 rgba($primary, 0.3);
  background-image: linear-gradient(to top, #6e60ee, #6078ee);
}
.btn-primary:hover, .btn-outline-primary:hover, .btn-primary.disabled, .btn-primary:disabled, .btn-outline-primary:disabled, .btn-primary:active, .btn-outline-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled):active {
  box-shadow: 0 0.25rem 0.50rem 0 rgba($primary, 0.6);
}
.btn-danger {
  box-shadow: 0px 6px 15px 0px rgba($black, 0.15);
  background-image: linear-gradient(to top, #ff1010, #fc2626);
}
.btn-danger:hover, .btn-outline-danger:hover, .btn-danger.disabled, .btn-danger:disabled, .btn-outline-danger:disabled, .btn-danger:active, .btn-outline-danger:active, .btn-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled):active {
  box-shadow: 4px 4px 15px rgba($danger, .3);
}
.full-screen-modal-footer .btn-primary, .floating-container-bottom .btn-primary {
  border-radius: 0px;
}
.btn-block-sm-down {
  @include media-breakpoint-down(sm) {
    display: block;
    width: 100%;
  }
}
.btn-plain {
  background-color: $white;
  color: $black;
  border: $white;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);
}
.btn-hover-animation {
  position: relative;
  overflow: hidden;
}
.btn-hover-animation::after {
  content: "";
  position: absolute;
  text-align: center;
  left: 50%;
  bottom: 0;
  width: 80%;
  height: 0.125rem;
  background-color: $primary;
  transform: translateX(-50%) scaleX(0);
  transition: transform 0.3s ease;
}
.btn-hover-animation:hover::after {
  transform: translateX(-50%) scaleX(1);
}
.book-button {
  margin: 1rem 0;
  text-transform: uppercase;
}
.gmail-btn {
  color: $white;
  background: #dd5347;
  border-color: #dd5347;
  font-size: 14px;
  img {
    position: absolute;
    left: 2em;
  }
}
.gmail-sign-btn, .apple-sign-btn {
  position: relative;
  background: $white;
  border-color: $gray;
  font-size: 14px;
  color: $black;

  svg-icon {
    position: absolute;
    left: 2px;
    top: 0px;
    height: 45px;
    width: 45px;
  }
  span {
    position: absolute;
    left: 18px;
    top: 15%;
    font-size: 26px;
  }
}
.btn-facebook, .btn-facebook:active, .btn-facebook:hover {
  position: relative;
  color: $white;
  background-color: #3b5998;
  border-color: rgba(0,0,0,0.2);
  span {
    position: absolute;
    left: 20px;
    top: 30%;
    font-size: 20px;
  }
}

.alert {
  padding: 1rem 1.25rem;
}

.visually-hidden {
  position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important;
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}
//End-Remove this after migrating to bootstrap 5.2

/* Section Styles
==================================== */
.section-divider {
  border-top: 1px solid $gray-blue;
  margin-top: 15px;
  padding-top: 10px;
}

/* Swiper (Carousel) Styles
==================================== */
.swiper-container {
  background-color: $gray-light;
}
.swiper-pagination-bullet {
  background-color: $white;
}
.swiper-slide {
  text-align: center;
}
.swiper-button-next, .swiper-button-prev {
  margin-top: -52px;
}
.swiper-button-prev {
  background-color: rgba($black, 0.3);
  left: 0;
  padding: 60px 30px;
}

.swiper-button-next {
  background-color: rgba($black, 0.3);
  right: 0;
  padding: 60px 30px;
}

/* Form Error Styles
==================================== */
.error-message {
  color: $red;
  font-size: 12px;
  margin-left: 2px;
}

.ng-touched.ng-invalid {
  border-color: $red;
}

.input-group {

  span, input {
    transition: $input-transition;
  }

  &.ng-touched.ng-invalid {
    & > span, input {
      border-color: $danger;
    }
  }
}

/* Modal Styles
==================================== */
.modal-dialog {
  .modal-header.modal-header-slim {
    padding: 0;
    .btn-close {
      height: 32px;
      width: 32px;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 7.5px;
      top: 17.5px;
    }
  }
  .modal-content {
    padding: 20px 10px;
    @include media-breakpoint-up(md) {
      padding: 30px 30px;
    }
    .modal-header {
      border: 0px;
      .btn-close {
        outline: none !important;
      }
    }
    .modal-footer {
      padding: 0px;
      border: 0px;
      text-align: center;
      display: inherit;
    }
  }
}
.modal-md .modal-dialog {
  max-width: 500px;
}

.modal-without-margin .modal-content {
  border: none;
  border-radius: 1rem;
   @include media-breakpoint-up(lg) {
    border-radius:  0.5rem !important;
    padding: 0;
    margin: 0;
  }
}

.modal-all-inclusive-search {
  .modal-lg {
    max-width: 992px !important;
  }
}

.modal-all-inclusive-search .modal-content {
  @include media-breakpoint-up(lg) {
    padding: 15px 30px !important;
  }
}
/* Tab Styles
====================================*/
.button-tabs-left {
  @media (min-width: 992px) {
    .profile-title {
      float: left;
    }
    .nav-tabs {
      float: right;
      width: 60%;
    }
    .content-tab {
      clear: both;
    }
  }
}
.button-tabs-left, .button-tabs-top {
  .content-tab {
    margin-bottom: 40px;
  }
  .nav-tabs {
    border: 1px solid #f8f9fa;
    border-radius: 4px;
    background-color: #f8f9fa;
    margin-bottom: 20px;

    .nav-link {
      border: 0px;
      color: $dark;
    }
    .nav-link:not(.active):hover {
      background-color: #e2e6ea;
      border-color: #dae0e5;
    }
    .active {
      border-radius: 4px;
      background-color: $primary;
      color: $white;
    }
  }
}

/* Alerts
===================================*/
.alert {
  a {
    text-decoration: underline;
  }
}
.alert-danger {
  background-color: $red;
}
.alert-warning {
  background-color: $yellow;
}
.alert-success {
  background-color: $secondary;
}

.alert-danger, .alert-success, .alert-warning {
  color: $white;
  a {
    color: $white;
  }
}

/* Mobile Styles
===================================*/
@media only screen and (max-width: 575.98px) {
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.1rem;
  }
}

/* Angular Bootstrap Datepicker
===================================*/

.ngbDatepicker {
  cursor: pointer;
  .dropdown-menu:before {
    top: -7px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 7px solid $gray;
    left: 9px;
    width: 0;
    height: 0;
  }
  .dropdown-menu:before, .dropdown-menu:after {
    position: absolute;
    display: inline-block;
    content: '';
  }
  .dropdown-menu:after {
    left: 10px;
  }
  .dropdown-menu:after {
    top: -6px;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $white;
    border-left: 6px solid transparent;
  }
  .dropdown-menu {
    border: 1px solid $gray;
    border-radius: 4px !important;
    margin-top: 5px;
    padding: 10px !important;
    background: $white;
    font-family: $font-family-base !important;
    .bg-light {
      background: $white !important;
    }
    .ngb-dp-month {
      width: 100%;
    }
    .ngb-dp-week {
      justify-content: space-around;
    }
    .ngb-dp-weekday {
      color: $black !important;
      font-weight: bold;
      font-style: inherit;
    }
    .ngb-dp-weekdays {
      border-bottom: 0px;
    }
    .form-select {
      border: 0px;
      font-size: 16px !important;
      font-weight: 700;
    }
    .btn-light:hover, .btn-light:active, .ngb-dp-day .active, .bg-primary {
      background-color: $primary !important;
      border-radius: 30px;
      color: $white !important;
      border: 0px;
      outline: thin dotted !important;
    }
    .ngb-dp-day:focus {
      outline: inherit !important;
    }
  }
}

.modal {
  .ngb-dp-months .ngb-dp-month {
    width: 100%;
    .ngb-dp-week {
      justify-content: space-around;
    }
    .ngb-dp-day, .custom-day-container, .custom-day {
      width: 100%;
    }
  }
}

/* Breadcrumbs
===================================*/
breadcrumb {
  width: 100%;
  .container {
    max-width: 100%;
  }
  .breadcrumb {
    padding: 10px 0;
    &:empty {
      display: none;
    }
  }
}

/* Room Level Overrides
===================================*/
.room-details {
  font-weight: 300;
  p {
    margin-bottom: 0 !important;
  }
}

// .ng-clear this does not handle the click. that's why the wrapper needs to be on top
ng-select {
  .ng-clear-wrapper {
    z-index: 2;
    .ng-clear {
      width: 20px;
      height: 20px;
    }
  }
}

/* Tooltip Styles
=================================== */
.tooltip.show {
  opacity: 1;
  pointer-events: none;
}
.tooltip-inner {
  background: $white;
  color: $dark;
  border: 0.5px solid $gray;
  text-align: left;
  -webkit-box-shadow: 0 0.625rem 1.25rem 0 rgba($gray-medium,.1);
  box-shadow: 0 0.625rem 1.25rem 0 rgba($gray-medium,.1);
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: $gray;
}

.custom-tooltip .tooltip-inner {
  opacity: unset;
}

.tooltip-dark {
  .tooltip-inner {
    background-color: $black;
    color: $white;
  }
}

/* Clear input directive styles: app/shared/directives/clear-input-directive.ts
=================================== */
.clear-input-button {
  align-self: center;
  background: transparent;
  border: none;
  height: 100%;
  position: absolute;
  right: 5px;
}

  /* Global Flight Styles
  =================================== */
.flight-segment-link {
  color: $primary !important;
  cursor: pointer;
}

.flight-row {
  column-gap: 1rem;

  .flight-col-1, .flight-col-2 {
    align-items: stretch;
  }

  .flight-col-2 {
    flex: 2;
  }

  .flight-col-1 {
    flex: 1;
  }
}

.flight-card {
  box-shadow: none;
  border-radius: 12px;

  .card-header, .card-footer {
    background-color: initial;
  }

  .card-footer {
    padding: .5rem;
  }

  .btn {
    font-size: .875rem;
    line-height: 150%;
    padding-bottom: .25rem;
    padding-top: .25rem;
  }
}

/* Badge Styles
=================================== */
.badge-promo {
  color: $promo-color;
  background-color: lighten($promo-color, 28%);
  z-index: 10;
}
.badge-promo-new {
  color: $primary;
  background-color: lighten($primary, 28%);
  z-index: 10;
}
.badge-pending {
  color: darken($yellow, 12%);
  background-color: lighten($yellow, 40%);
}
.badge-new-property {
  background-color: #fcefd2;
}
.badge-all-inclusive {
  color: darken($indigo, 12%);
  background-color: lighten($indigo, 38%);
}
.badge-percentoff {
  color: darken($cyan, 12%);
  background-color: lighten($cyan, 66%);
}
.badge-gray {
  color: $gray-medium;
  background-color: lighten($gray-medium, 45%);
}
.badge-success {
  color: darken($secondary, 12%);
  background-color: lighten($secondary, 45%);
}
.badge-light {
  color: darken($gray-blue-light, 40%);
  background-color: $gray-blue-light;
}
.badge-warning {
  color: $red;
  background-color: lighten($red, 38%);
}

/* Payment & Insurance Styles
================================ */
.card-container {
  border: 1px solid $gray;
  border-radius: 6px;

  img {
    border-radius: 0;
  }
}

.payment-selection {
  position: relative;
  width: 100%;
  .paypal-logo, .card-images {
    position: absolute;
    width: 100%;
    top: 0;
  }
  .paypal-logo > img {
    height: 120px;
    margin-top: -35px;
  }
  .card-images > img {
    height: 28px;
    margin-left: 5px;
    border-radius: 0;
  }
}

.gray {
  background-color: $gray-light;
}

.section-title {
  @extend .gray;
  padding: 8px 0 8px 15px;
  font-size: 14px;
  font-weight: bold;
  margin-right: -1.25rem;
}

/* grayscale disabled*/
.grayscale-disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  cursor: not-allowed;
}
/* grayscale and disabled*/
.grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.disabled {
  cursor: not-allowed;
}

.airline-logo-header {
  max-height: $h2-font-size;
  border-radius: 6px;
}

.airline-logo {
  max-height: $h4-font-size;
  border-radius: 6px;
}

/* Home and Flight page tabs
 ===========================*/

 .form-tabs {

  .tab-content {
    background-color: $white;
    display: flex;
    align-items: center;
    padding: 32px 20px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    .input-icon {
      font-size: 18px;
      right: inherit;
      z-index: 999;
      color: $primary;
    }
  }
  .tab-pane {
    width: 100%;
  }
  .nav-tabs .nav-link {
    margin-right: 4px;
    background-color: $gray-blue-light;
    color: $temp-light;
    border-bottom: 4px solid $gray-blue-light;
    text-transform: uppercase;
    line-height: 36px;
    width: fit-content;
    text-align: center;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .nav-tabs .nav-link.active {
    background-color: $white;
    color: $dark;
    border-bottom: 4px solid $white;
  }
}
/* Ticketing and listing forms
 ===========================*/

 .section-title {
  background-color: $white;
  margin-right: -2.25em;
  padding-top: 0px;

  &-divider {
    background-color: $gray;
    height: 1px;
    margin-top: 18px;
  }

  &-label {
    font-size: 17px;
    margin-left: 10px;
    float: left;
    -webkit-text-fill-color: $gray-medium;
  }
}
.passenger-checkbox {
  margin: 4px 10px 0px 15px;
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.passenger-label {
  color: $black;
  cursor: pointer;
}

/* Prediction Walktrough
 ===========================*/
.prediction-walktrough {
  padding: 1rem;
  background-color: $white;

  .shepherd-title,
  .shepherd-text {
    color: $black;
    font-size: 0.875rem;
  }

  .shepherd-title {
    font-weight: bold;
  }

  *,
  .shepherd-header {
    padding: 0 !important;
  }

  .shepherd-header {
    background-color: inherit !important;
    margin-bottom: 0.25rem;
  }

  .shepherd-arrow::before {
    background-color: $white !important;
  }

  .shepherd-button {
    background-color: inherit;
    color: $primary;
    font-size: 0.875rem;
    font-weight: bold;

    &:not(:disabled):hover {
      color: $primary;
      background-color: mix($primary, $white, 15%);
    }
  }

  .shepherd-cancel-icon {
    line-height: 1rem;
  }
}

/* iOS Safari Hack
 ===========================*/
.ios-toolbar-gone {
  .floating-container-bottom {
    transform: translateY(-25px);
  }
}

/* IE11
 ===========================*/
input[clear-input]::-ms-clear {
  // hide IE "native" clear input button
  display: none;
  height: 0;
  width: 0;
}

.bg-primary {
  background-color: $primary !important;
}

.bg-gray-light {
  background-color: $gray-light;
}

.navBarWithBeamer  {
  top : 50px !important;
  padding: 8px 16px !important;

  @include media-breakpoint-down(md) {
    min-height: 110px!important;
    display: flex;
    align-content: baseline;
  }
}
.navBarWithBeamerWith0  {
  top : 0px !important;
}
.beamerWhithIE11Alert {
  top: 52px !important;
}

.rotation-animation {
  display: inline-block;
  transform: rotate(45deg);
  transition: transform 3s;
}
.modal-transparent .modal-dialog .modal-content {
  background-color: transparent;
  border: none;
}

@include media-breakpoint-down(lg) {
  .modal-dialog.modal-fullscreen-md-down .modal-content {
    padding: 0;

    .modal-header {
      box-shadow: 0 .598px 1px #d2d6df;

      &.modal-header-slim {
        padding: $modal-header-padding;

        .btn-close {
          height: initial;
          margin: (-$modal-header-padding-y) (-$modal-header-padding-x) (-$modal-header-padding-y) auto;
          padding: $modal-header-padding;
          position: initial;
          width: initial;
        }
      }
    }

    .modal-body {
      padding: .5rem;
    }

    .modal-footer {

      .btn.w-100 {
        border-radius: 0;
        letter-spacing: normal;
        margin: 0;
      }
    }
  }
}

.affirm-iframe-widget {
  display: none !important;
}

footer a:not(:hover):not(:active):not(:focus) {
  text-decoration: none;
}
